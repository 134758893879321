import React, { useEffect } from 'react';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    SimpleGrid,
    Text,
    VStack,
  } from '@chakra-ui/react';
  import { FaCaretRight } from 'react-icons/fa';
  import { Link } from 'react-router-dom';
import ChartLayout from '../components/Layouts/ChartLayouts/ChartLayout';
import PowerFlowSVG from '../assets/PlantView/PowerFlow';
import { PiFlowArrowBold } from "react-icons/pi";
import PlantViewCalculationCardLayout from '../components/Layouts/PlantViewCalculationCardLayout';
import AreaSplineChart from '../components/widgets/charts/AreaSplineChart';
import ColumnChart from '../components/widgets/charts/ColumnChart';
import { FaChartArea, FaChartColumn } from 'react-icons/fa6';
import PlantTable from '../components/widgets/tables/PlantTable';
import GridBG from '../assets/PlantView/PlantViewGridBG.svg';
import DGBG from '../assets/PlantView/PlantViewDGBG.svg';
import SolarBG from '../assets/PlantView/PlantViewSolarBG.svg';
import BESSBG from '../assets/PlantView/PlantViewBESSBG.svg';
import PlantViewTableLayout from '../components/Layouts/TableLayouts/PlantViewTableLayout';
import UseBatteryStatus from '../Services/Hooks/Battery/UseBatteryStatus';
import { useTimeHandle } from '../Services/TimeWindowSetting';
import UseBESSDaily from '../Services/Hooks/Battery/UseBESSDaily';
import UsePlantCard from '../Services/Hooks/PlantView/UsePlantCard';
import UsePlanViewTable from '../Services/Hooks/PlantView/UsePlantViewTable';
import { html } from 'gridjs';
import UseManyDeviceManyKeysChart from '../Services/Hooks/UseManyDeviceManyKeysChart';
import Heatmap from '../components/widgets/Heatmap';
import TopRibbon from '../components/TopRibbon';
import UsePlanViewBlocksTable from '../Services/Hooks/PlantView/UsePlantVeiwBlocksTable';
import UseInverterAvailability from '../Services/Hooks/UseInverterAvailability';
import UsePlantStatusCard from '../Services/Hooks/PlantView/UsePlantStatusCard';

const PlantViewDashboard = () => {

    // *********************************Power FLow****************
    var search = {
        devName : "Calculation",
        keys : "INV_Total_Power"
    }
    const batteryStatus = UseBatteryStatus(search) || [];

    var search2 = {
        devName : "Grid",
        keys : "Grid_Power_kW" 
    }
    const batteryStatus2 = UseBatteryStatus(search2) || [];

    var search3 = {
        devName : "DG-1",
        keys : "B1_DG_DG_1_AC_Active_Power_Watt" // Generally this tag is come from Calculation
    }

    const batteryStatus3 = UseBatteryStatus(search3) || [];

    // ********************* Daily Energy Graph *********************
    const {
        timeWindow: timeWindowPowerColumn,
        handleTimeWindowChange: handleTimeWindowPowerColumnChange,
        handleReset: PowerColumnHandleReset
    } = useTimeHandle(7, "day", "NONE", [5, "minute"]);

    
    var searchTagPowerColumn = [
        {
            devName : "Grid",
            keys : "Node_SL_PV_Daily_Energy,Grid_Daily_Energy_Export_kWh", //Node_Generation_Loss_BP : PG API doesn't work
            type : ["column","column"],
            name : ["PV Daily Energy","Grid Consumption kWh"]   
        },
        {
            devName : "DG-1",
            keys : "B1_DG_DG_1_Energy_Daily_kwh",
            type : ["column"],
            name : ["DG Daily Energy"]
        },
    ];
    const PowerColumnData = UseManyDeviceManyKeysChart(searchTagPowerColumn, timeWindowPowerColumn, "LastValue")?.map((series : any, index : number) => {
        if(series.name == "PV Daily Energy") {
            return {
                ...series,
                color : "#19CA16"
            }
        } else if(series.name == "Grid Consumption kWh") {
            return {
                ...series,
                color : "#8842E0"
            }
        } else if(series.name == "DG Daily Energy") {
            return {
                ...series,
                color : "#10BDF3"
            }
        } else {
            return {
                ...series,
                color : "#CF4E4E"
            }
        }
    });
    // useEffect(() => {
    //     if (PowerColumnData) {
    //         console.log("PowerColumnData:", PowerColumnData);
    //     }
    // }, [PowerColumnData]);

    // ********************* Power Curve *********************
    const {
        timeWindow: timeWindowPowerCurve,
        handleTimeWindowChange: handleTimeWindowPowerCurveChange,
        handleReset: PowerCurveHandleReset
    } = useTimeHandle(12, "hour", "NONE", [5, "minute"]);

    
    var searchTagPowerCurve = [{ 
        devName : "Calculation",
        keys : "INV_Total_Power,Load_Power",
        type : ["areaspline","areaspline"],
        name : ["PV kW","Load kW"]
    },
    { 
        devName : "DG-1",
        keys : "B1_DG_DG_1_AC_Active_Power_Watt",
        type : ["areaspline"],
        name : ["DG kW"]
    },
    { 
        devName : "Grid",
        keys : "Grid_Power_kW",
        type : ["areaspline"],
        name : ["Grid kW"]
    }
    ];

    const PowerCurveColor = ["#19CA16", "#189269", "#8842E0", "#CF4E4E"];
    const PowerCurveData = UseManyDeviceManyKeysChart(searchTagPowerCurve, timeWindowPowerCurve)?.map((series : any, index : number) => {
        if(series.name == "PV kW") {
            return {
                ...series,
                color : "#19CA16"
            }
        } else if(series.name == "Grid kW") {
            return {
                ...series,
                color : "#8842E0"
            }
        } else if(series.name == "DG kW") {
            return {
                ...series,
                color : "#10BDF3"
            }
        } else {
            return {
                ...series,
                color : "#CF4E4E"
            }
        }
    });
    // useEffect(() => {
    //     if (PowerCurveData) {
    //         console.log("PowerCurveData:", PowerCurveData);
    //     }
    // }, [PowerCurveData]);

    // ******************** STATUS *********************

    const StatusCardLabel = ["Plant Availability", "Grid Availability", "Grid Out", "Grid Occurrence", "Plant Downtime", "Plant Downtime Occurrence", "Load Loss"];
    const StatusCardData = UsePlantStatusCard() || [[]];

    //******************************Solar Card*************** */
    // Columns => Always "Key"
    // Values => Telemetry Name
    // Status => 0 : AVG / 1 : SUM
    var searchSolarCard = '[{"columns" : "Key","values" : "INV_Total_Power","status" : 0},{"columns" : "Key","values" : "Node_SL_PV_Daily_Energy","status" : 1},{"columns" : "Key","values" : "PV_Total_Energy_kWh","status" : 1},{"columns" : "Key","values" : "SL_CUF","status" : 0},{"columns" : "Key","values" : "SL_Co2_Saving","status" : 0}]'
    var SolarDataLabel = ["PV Power kW", "PV Generation kWh", "PV Lifetime Generation MWh", "CUF %", "CO2 Saving Tons"]
    const SolarPlantCardData = UsePlantCard(searchSolarCard, SolarDataLabel) || [[]];
    // console.log(SolarPlantCardData)

    //******************************DG Card*************** */
    // Columns => Always "Key"
    // Values => Telemetry Name
    // Status => 0 : AVG / 1 : SUM
    var searchDGCard = '[{"columns" : "Key","values" : "B1_DG_DG_1_AC_Active_Power_Watt","status" : 0},{"columns" : "Key","values" : "B1_DG_DG_1_Energy_Daily_kwh","status" : 1},{"columns" : "Key","values" : "B1_DG_DG_1_VOLT_L1_L2","status" : 1},{"columns" : "Key","values" : "Daily_Runtime_Sum","status" : 1},{"columns" : "Key","values" : "Daily_Saving_Sum","status" : 1}]'
    var DGDataLabel = ["DG Power kW", "DG Generation kWh", "DG Line Voltage V", "DG Runtime Min", "Savings INR"]
    const DGPlantCardData = UsePlantCard(searchDGCard, DGDataLabel) || [[]];
    // console.log(DGPlantCardData)

    //******************************Grid Card*************** */

    var searchGridCard = '[{"columns" : "Key","values" : "Grid_Power_kW","status" : 0},{"columns" : "Key","values" : "Bus_B_frequency_L1","status" : 0},{"columns" : "Key","values" : "Grid_Daily_Energy_Export_kWh","status" : 1}, {"columns" : "Key","values" : "Grid_Daily_Energy_Import_kWh","status" : 0},{"columns" : "Key","values" : "SL_Bus_B_voltage_L1_L2","status" : 0}, {"columns" : "Key","values" : "AGC_AMP_L1","status" : 0}]'
    var GridDataLabel = ["Grid kW", "Grid Hz", "Consumtion kWh", "Feed kWh", "Line Voltage V", "Line Current A"]
    const GridPlantCardData = UsePlantCard(searchGridCard, GridDataLabel) || [[]];
    // console.log("GridPlantCardData", GridPlantCardData)


    // // ******************** Inverter Table ***********************
    
    // var searchInverterTable = {
    //     Inverter : "B1_Inverter_Inverter_0_Inverter_Communication,B1_Inverter_Inverter_0_AC_Active_Power_Watt,B1_Inverter_Inverter_0_AC_Reactive_Power_var,B1_Inverter_Inverter_0_AC_Apparent_Power_VA,B1_Inverter_Inverter_0_Active_Power_referance,B1_Inverter_Inverter_0_Energy_Daily_kWh,B1_Inverter_Inverter_0_Energy_Total_kWh,B1_Inverter_Inverter_0_Frequency_Hz,B1_Inverter_Inverter_0_Volt_L1_L2,B1_Inverter_Inverter_0_Volt_L2_L3,B1_Inverter_Inverter_0_Volt_L3_L1,B1_Inverter_Inverter_0_Fault_Code"
    // }
    // var InverterColumn = ["Name", {
    //     name : "State",
    //     width : "200px",
    //     formatter: (cell: any) => parseFloat(cell) > 0 ? html(`<div style="width:100%; display: flex; justify-content: center; align-items: center;"><div style="background:green; height:15px; width:15px; border-radius:50%;"></div></div>`) : html(`<div style="width:100%; display: flex; justify-content: center; align-items: center;"><div style="background:red; height:15px; width:15px; border-radius:50%;"></div></div>`)
    //     }, "Power kW", "Power kVAR", "Power KVA", "kW % Ref", "Daily Energy", "Total Energy", "Frequency Hz", "L1-L2 Volts", "L2-L3 Volts", "L3-L1 Volts", "Fault State"]

    // const InverterTableData = UsePlanViewTable(searchInverterTable) as any;
    // console.log("InverterTableData", InverterTableData);

    // ******************** PV Meter Table ***********************
    
    // var searchPVMeterTable = {
    //     PV : "ASC4_POWER,ASC4_AMP_L1,ASC4_AMP_L2,ASC4_AMP_L3,ASC4_APPARENT_POWER,ASC4_HZ_L1,ASC4_PF,ASC4_REACTIVE_POWER,ASC4_VOLT_L1_L2,ASC4_VOLT_L2_L3,ASC4_VOLT_L3_L1,ASC4_ENERGY_Total"
    // }
    // var PVMeterColumn = ["Name", "Power kW", "L1 Amps", "L2 Amps", "L3 Amps", "Power KVA", "Frequency Hz", {
    //     name : "PF      9",
    //     widht : "200px",
    // }, "Power kVAR", "L1 Volts", "L2 Volts", "L3 Volts","Total Energy"]

    // const PVMeterTableData = UsePlanViewTable(searchPVMeterTable) as any;
    // console.log("PVMeterTableData", PVMeterTableData);

    // ******************** Generator Table ***********************
    
    // var searchGeneratorTable = {
    //     DG : "B1_DG_DG_0_Energy_Daily_kwh,B1_DG_DG_0_Energy_Total_kwh,B1_DG_DG_0_AC_Reactive_Power_var,B1_DG_DG_0_AMP_L1,B1_DG_DG_0_AMP_L2,B1_DG_DG_0_AMP_L2,B1_DG_DG_0_AMP_L3,B1_DG_DG_0_Frequency_Hz_L1,B1_DG_DG_0_VOLT_L1_L2,B1_DG_DG_0_VOLT_L2_L3,B1_DG_DG_0_VOLT_L3_L1,B1_DG_DG_0_Fuel_Rate,B1_DG_DG_0_Engine_Speed_RPM"
    // }
    // var GeneratorColumn = ["Name","Power kW","Daily Export kWh","Power kVAR","L1 Amps","L2 Amps","L3 Amps","Frequency Hz","L1-L2 Volts","L2-L3 Volts","L3-L1 Volts","Fuel Rate","Engine Speed"]
    // const GeneratorTableData = UsePlanViewTable(searchGeneratorTable) as any;
    // console.log("GeneratorTableData", GeneratorTableData);

    // ******************** Blocks Inverter Table ***********************
    
    var searchInverterTable = [
        {
            "Calculation" : "INV_Total_Power,INV_Total_Energy_kWh,PV_Daily_Energy_Today,SL_INV_Load_Percent_kW,SL_CUF"
        },
        {
            "Calculation" : "INV_Total_Power,INV_Total_Energy_kWh,PV_Daily_Energy_Today,SL_INV_Load_Percent_kW,SL_CUF"
        },
    ]
    var InverterColumn = [
        {name : "Name", width : 100, formatter : (cell : any) => html(`<b style="padding-left : 20px">${cell}</b>`)}, 
        {name : "Power", width : 100}, 
        {name : "Total Power", width : 180},
        {name : "Energy", width : 100},
        {name : "Load %"},
        {name : "CUF"}
    ];

    const InverterTableData = UsePlanViewBlocksTable(searchInverterTable) as any;


    return (
      <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
            <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={12}>
                    <BreadcrumbLink>Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={12} as={Link} to="/portfolio">
                    Portfolio
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12}>
                    Sites
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12} as={Link} to="/grid">
                    Plant View
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            {/* ****************** TOP RIBBON ************ */}
            <TopRibbon />

            <Box mt={10} mb={3} display={["none", "none", "block"]}>
                <ChartLayout
                    width={["full", "100%"]}
                    height='265px'
                    title='Plant'
                    px='0'
                    icon={PiFlowArrowBold}
                >
                    <PowerFlowSVG
                        SolarValue={batteryStatus[0] || 0}
                        DGValue={batteryStatus3[0] || 0}
                        GridValue={batteryStatus2[0] || 0}
                        LoadValue={parseFloat((batteryStatus[0]+batteryStatus2[0]+batteryStatus3[0]).toFixed(2)) || 0}
                    />
                </ChartLayout>
            </Box>
            <Flex h={"max-content"} overflowX={"auto"} overflowY={"hidden"} gap={1}>
                    <GridItem h={360} colSpan={1}>
                        <PlantViewCalculationCardLayout
                            width={["full", "350px"]}
                            height='100%'
                            title='Status'
                            bg={"linear-gradient(to top right, #99BC85 20%, #E1F0DA 60%, #73EC8B)"}
                            data={StatusCardData || []}
                            DataLabel={StatusCardLabel}
                        />
                    </GridItem>
                    <GridItem h={356} colSpan={1}>
                        <PlantViewCalculationCardLayout
                            width={["full", "350px"]}    // 317px
                            height='100%'
                            title='Grid'
                            bg={`url(${GridBG}) no-repeat center/cover`}
                            data={GridPlantCardData || [[]]}
                            DataLabel={GridDataLabel}
                        />
                    </GridItem>
                    <GridItem h={360} colSpan={1}>
                        <PlantViewCalculationCardLayout
                            width={["full", "350px"]} // 317px
                            title='Solar'
                            height='100%'
                            bg={`url(${SolarBG}) no-repeat center/cover`}
                            data={SolarPlantCardData || [[]]}
                            DataLabel={SolarDataLabel}
                        />
                    </GridItem>
                    <GridItem h={356} colSpan={1}>
                        <PlantViewCalculationCardLayout
                            width={["full", "350px"]}    // 317px
                            height='100%'
                            title='DG'
                            bg={`url(${DGBG}) no-repeat center/cover`}
                            data={DGPlantCardData || [[]]}
                            DataLabel={DGDataLabel}
                        />
                    </GridItem>
                    <GridItem h={356} colSpan={1}>
                        <PlantViewCalculationCardLayout
                            width={["full", "350px"]}    //317px
                            height='100%'
                            title='BESS'
                            bg={`url(${BESSBG}) no-repeat center/cover`}
                            data={GridPlantCardData || [[]]}
                            DataLabel={GridDataLabel}
                        />
                    </GridItem>
                {/* </SimpleGrid> */}
            </Flex>
            <SimpleGrid
                h={"auto"}
                maxW={"8xl"}
                minChildWidth={["280px","500px"]}
                // templateRows="repeat(1, 1fr)"
                // templateColumns="repeat(2, 1fr)"
                gap={1}
                mt={[0, 0, -3]}
                // mb={0}
            >
                <GridItem w={"100%"}>
                    <ChartLayout
                        title='Power Curve'
                        width={["full", "100%"]}
                        height='317px'
                        icon={FaChartArea}
                        timeWindow={true}
                        onTimeWindowChange={handleTimeWindowPowerCurveChange}
                        onReset={PowerCurveHandleReset}
                        fullScreen={true}
                    >
                        <AreaSplineChart height={270} props={{yAxis : {title : {text : "AC Power"}}}} apiData={PowerCurveData || [{}]} />
                    </ChartLayout>
                </GridItem>
                <GridItem w={"100%"}>
                    <ChartLayout
                        title='Daily Energy'
                        width={["full", "100%"]}
                        height='317px'
                        icon={FaChartColumn}
                        timeWindow={true}
                        onTimeWindowChange={handleTimeWindowPowerColumnChange}
                        onReset={PowerColumnHandleReset}
                        fullScreen={true}
                    >
                        <ColumnChart height={270} props={{yAxis : {title : {text : "kWh"}}}} apiData={PowerColumnData || [{}]} />
                    </ChartLayout>
                </GridItem>
            </SimpleGrid>
            <SimpleGrid
                h={"auto"}
                maxW={"8xl"}
                minChildWidth={["280px","500px"]}
                column={2}
                mt={[0, 0, -3]}
                gap={2}
            >
                    <GridItem width={["100%","auto"]} h={"100%"} colSpan={1}>
                        <PlantViewTableLayout
                            title='Inverter'
                            width={["full", "100%"]}
                            height='400px'
                        >
                            <PlantTable
                                paginationLimitProps={8}
                                column={InverterColumn}
                                apiData={InverterTableData || []}
                            />
                        </PlantViewTableLayout>
                    </GridItem>
                    {/* *** Here was Table OF DG & PV-Meter *** */}
                    {/* <GridItem w={"100%"} colSpan={1}> */}
                        {/* <VStack w={["100%","auto"]} h={"100%"}>
                            <Box w={"100%"} overflow={'auto'}>
                                <PlantViewTableLayout
                                    title='PV Meter'
                                    width={["full", "100%"]}
                                    height='150px'
                                >
                                    <PlantTable
                                        paginationLimitProps={5}
                                        column={PVMeterColumn}
                                        apiData={PVMeterTableData || []}
                                    />
                                </PlantViewTableLayout>
                            </Box>
                            <Box w={"100%"} mt={[0, 0, -6]}>    
                                <PlantViewTableLayout
                                    title='Generator'
                                    width={["full", "100%"]}
                                    height='244px'
                                >
                                    <PlantTable
                                        paginationLimitProps={5}
                                        column={GeneratorColumn}
                                        apiData={GeneratorTableData || []}
                                    />
                                </PlantViewTableLayout>
                            </Box>
                        </VStack> */}
                    {/* </GridItem> */}

                    {/* ****HEATENAP**** */}
                <GridItem width={"100%"} height={"100%"} colSpan={1}>
                    <ChartLayout
                        title='Heatmap'
                        width={["full", "100%"]}
                        height={"400px"}
                    >
                        <Heatmap />
                    </ChartLayout>
                </GridItem>
            </SimpleGrid>
            {/* <SimpleGrid
                height={300}
                minChildWidth={["500px"]}
            >
            </SimpleGrid> */}
      </Box>
    );
  };
  
  export default PlantViewDashboard
  