import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useTimeHandle } from "../../../Services/TimeWindowSetting";
import UseBESSDaily from "../../../Services/Hooks/Battery/UseBESSDaily";
import ChartLayout from "./ChartLayout";
import LineChart from "../../widgets/charts/LineChart";

interface ChartOnModalType {
    isOpen: boolean;
    onClose: () => void;
    devName : string;
}

const ChartOnModal: React.FC<ChartOnModalType> = ({ isOpen, onClose, devName }) => {

    // ******************Modal Chart *******************
    const {
        timeWindow: timeWindowModalChart,
        handleTimeWindowChange: handleTimeWindowModalChartChange,
        handleReset: ModalChartHandleReset
    } = useTimeHandle(1, "cdsf", "NONE", [5, "minute"]);


    const keys = "B1_Inverter_Inverter_0_AC_Active_Power_Watt,B1_Inverter_Inverter_0_AC_Reactive_VAr,B1_Inverter_Inverter_0_AC_Apparant_Power_VA"
    var searchTagModalChart = {     
        devName : devName,
        keys: keys.replace(/0/g, devName.split("-")[1]),
        type : ["spline", "spline", "spline"],
        name : ["Active Power", "Reactive Power", "Apparent"],
        marker : false
    };
    // const ModalChartColor = ["#3853A5", "#F4B725"];
    const ModalChartData = UseBESSDaily(searchTagModalChart, timeWindowModalChart);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size={"6xl"}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent>
                <ModalHeader>{devName} Chart</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ChartLayout
                        title=""
                        width={["full", "full"]}
                        height={"auto"}
                        timeWindow={false}
                        onTimeWindowChange={handleTimeWindowModalChartChange}
                        onReset={ModalChartHandleReset}
                        fullScreen={false}
                        bg="transparent"
                    >
                        <LineChart height={300} apiData={ModalChartData || []} />
                    </ChartLayout>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ChartOnModal;
