import axios from "axios";

// APIs from main server

const BASE_URL = 'https://www.cogneta.cloud/api';

const GetUserInfo = async (userID : string, token : string) => {
    try {

        const response = await axios.get(`${BASE_URL}/user/info/${userID}`, {headers : {'X-Authorization' :`Bearer ${token}`}});
        return response;

    } catch (error) {
        console.error("Getting error while fetching User Info!");
    }
};

export default GetUserInfo;