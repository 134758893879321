import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface columnLineType {
    apiData? : Object[];
    bg? : string;
    height? : number;
    isFullscreen? : boolean;
}

const ColumnLineChart : React.FC <columnLineType> = ({apiData, bg, height=220, isFullscreen=false}) => {

    const [chartOptions, setChartOptions] = useState({
        chart : {
            backgroundColor : bg || "transparent",
            height : height
        },
        title: {
            text: ''
        },
        xAxis: {
            type: "datetime",
            label : {
                enabled : false,
            }
        },
        yAxis: [{
            title: {
                text: 'Primary Axis'
            }
        }, {
            title: {
                text: 'Secondary Axis'
            },
            opposite: true
        }],
        series: [] as Object[],
        credits : {
            enabled : false
        },
        exporting : {
            enabled : true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'downloadJPEG', // Allow JPEG export
                        'separator', 
                        'downloadCSV'   // Allow CSV export
                    ]
                }
            }
        }
    });

    // Adjust chart height based on full-screen state
    useEffect(() => {
        const newHeight = isFullscreen ? 800 : height;
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: newHeight
            }
        }));
    }, [isFullscreen, height]);

    useEffect(() => {
        if (apiData) {
            setChartOptions({
                ...chartOptions,
                series: apiData || []
            });
        }
    }, [apiData]);

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default ColumnLineChart;