import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, HStack, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { FaAngleLeft, FaAngleRight, FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import UseDeviceOverview from "../Services/Hooks/DeviceOverview/UseDeviceOverview";
import { useState } from "react";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import ChartOnModal from "../components/Layouts/ChartLayouts/ChartOnModal";
import { IoSearchSharp } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";

interface dataType {
    name : string;
    serial : string;
    status : boolean;
    power : number;
    energy : number;
    action : string;
    lastUpdate : string;
    hasNext : boolean;
    totalPages : number;
}

const DeviceOverviewDashboard = () => {
    const [textSearch, setTextSearch] = useState("");
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value); // Update textSearch on input change
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    // Create a state to hold the selected action for the modal
    const [selectedAction, setSelectedAction] = useState<string | null>(null);
     // Function to handle opening the modal with the selected action
     const handleOpenModal = (action: string) => {
        setSelectedAction(action); // Set the current row's action
        onOpen(); // Open the modal
    };
    
    const [page, setPage] = useState(0);
    const pageSize = 14;
    
    const data = UseDeviceOverview(pageSize, page, textSearch) as dataType[];
    const filteredData = data?.filter(elem => (elem.action.toLowerCase() !== "calculation"));

    const handleNext = () => {
        if(data[0].hasNext) {
            setPage(page+1);
        }
    }
    const handlePrev = () => {
        if(page > 0) {
            setPage(page-1);
        }
    }

    const handleFirst = () => {
        setPage(0);
    }

    const handleLast = () => {
        setPage(data[0].totalPages - 1);
    }

    return (
        <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
            <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={12}>
                    <BreadcrumbLink>Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={12} as={Link} to="/portfolio">
                        Portfolio
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12}>
                        Sites
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12} as={Link} to="/overview">
                        Overview
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Box>
                <Flex w={"full"} justify={"end"} >
                    <InputGroup w={80}>
                        <Input
                            pr='4.5rem'
                            type={'text'}
                            placeholder='Enter Device Name'
                            onChange={handleInputChange}
                        />
                        <InputRightElement width='4.5rem'>
                                <IoSearchSharp />
                        </InputRightElement>
                    </InputGroup>
                </Flex>
                <TableContainer bg={"white"} mt={5}>
                    <Table variant={"simple"} textAlign="center" size={"sm"}>
                        <Thead fontSize={14} fontWeight={500}>
                            <Tr borderBottom={"2px solid black"}>
                                <Td textAlign={"center"}>Name</Td>
                                <Td textAlign={"center"}>Serial</Td>
                                <Td textAlign={"center"}>Status</Td>
                                <Td textAlign={"center"}>Power</Td>
                                <Td textAlign={"center"}>Energy</Td>
                                <Td textAlign={"center"}>Last Update</Td>
                                <Td textAlign={"center"}>Action</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredData && filteredData?.map((elem, i) => (
                                <Tr key={i} fontSize={16}>
                                    <Td textAlign={"center"} color={"blue.900"} fontWeight={600}>{elem.action}</Td> {/* action is Label */} 
                                    <Td textAlign={"center"}>{elem.serial}</Td>
                                    <Td 
                                        color={elem.status? "green.500" : "red.500"}
                                        w={"min-content"} 
                                        p={-10}
                                        textAlign={"center"}
                                        borderRadius={"16px"}
                                        fontWeight={600}
                                        // bgColor={elem.status? "rgba(25, 128, 56, 0.08)" : "rgba(209, 39, 48, 0.08)"}
                                    >
                                        <Text>{elem.status? "Active" : "Inactive"}</Text>
                                    </Td>
                                    <Td textAlign={"center"}>{elem.power}</Td>
                                    <Td textAlign={"center"}>{elem.energy}</Td>
                                    <Td textAlign={"center"}>{elem.lastUpdate}</Td>
                                    <Td textAlign={"center"}>
                                        <Menu size={"sm"} placement="left">
                                            <MenuButton>
                                                <HiDotsVertical />
                                            </MenuButton>
                                            <MenuList minWidth={"20px"} fontSize={14} color={"green.500"}>
                                                <MenuItem
                                                    fontWeight={600}
                                                    onClick={() => handleOpenModal(elem.action)}
                                                    _focus={{ fontWeight : 700}}
                                                >
                                                    Show Chart
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <HStack m={5} mx={10} justify={"end"}>
                    <Tooltip label='First Page'>
                        <Box as="span" cursor={"pointer"}>
                            <FaAnglesLeft onClick={handleFirst} color={page==0 ? "#999" : "#000"} fontSize={20} />
                        </Box>
                    </Tooltip>
                    <Tooltip label='Previous'>
                        <Box as="span" cursor={"pointer"}>
                            <FaAngleLeft onClick={handlePrev} color={page==0 ? "#999" : "#000"} fontSize={20} />
                        </Box>
                    </Tooltip>
                    <Tooltip label='Next'>
                        <Box as="span" cursor={"pointer"}>
                            <FaAngleRight onClick={handleNext} color={page==(data[0]?.totalPages-1 || 0) ? "#999" : "#000"} fontSize={20} />
                        </Box>
                    </Tooltip>
                    <Tooltip label='Last Page'>
                        <Box as="span" cursor={"pointer"}>
                            <FaAnglesRight onClick={handleLast} color={page==(data[0]?.totalPages-1 || 0) ? "#999" : "#000"} fontSize={20} />
                        </Box>
                    </Tooltip>
                </HStack>

                </TableContainer>
            </Box>
            {/* Only show the modal if selectedAction is set */}
            {selectedAction && (
                <ChartOnModal isOpen={isOpen} onClose={onClose} devName={selectedAction}/>
            )}
        </Box>
    );
};

export default DeviceOverviewDashboard;