import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, Image, Text } from "@chakra-ui/react";
import ReportSelector from "../components/ReportSelector";
import { useEffect, useState } from "react";
import PlantViewTableLayout from "../components/Layouts/TableLayouts/PlantViewTableLayout";
import PlantTable from "../components/widgets/tables/PlantTable";
import Cogneta from '../assets/cogneta.png';
import { useCustomerOptionsContext } from "../Context/CustomerOptionsContext";
import { useTimeHandle } from "../Services/TimeWindowSetting";
import UseGeneratorTable from "../Services/Hooks/UseGeneratorTable";
import { html } from "gridjs";
import { FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const Papa = require('papaparse');

const ReportDashboard = () => {
    
    const { customerOptions } = useCustomerOptionsContext();
    var selectedCustomerID = localStorage.getItem("SelectedCustomerId");
    var currentCustomerName = customerOptions?.find(option => option.value == selectedCustomerID);

    const [searchTag, setSearchTag] = useState<Object[]>([{Calculation : "INV_Total_Power"}, {Meter : "Grid_Power_kW"}, {Calculation : "DG_Total_Power"}, {Calculation : "SL_Load_Power"}]);
    const [label, setLabel] = useState(["INV Power kW", "Grid Power kW", "DG Power kW", "Load Power kW"]);
    
    // ********************** Power Table Previous (Date & Time First Row) *********************
    
    const {
        timeWindow : timeWindowPowerTable,
        handleTimeWindowChange : handleTimeWindowPowerTableChange,
        handleReset : handlePowerTableReset
    } = useTimeHandle(1, "cdsf", "NONE", [5, "minute"]);

    var PowerTableData = UseGeneratorTable(searchTag, label, timeWindowPowerTable); 

    
    // ********************** Energy Table Previous (Date & Time First Row) *********************
    
    
    const [currentReport, setCurrentReport] = useState("Power Report");
    const [currentReportData, setCurrentReportData] = useState(PowerTableData);

    useEffect(() => {
        if(PowerTableData) {
            setCurrentReportData(PowerTableData)
        }
    }, [PowerTableData, searchTag, label])

    const handleOptionChange = (newOption : any) => {
        setCurrentReport(newOption.label);

        switch (newOption.value) {
            case 0:
                setSearchTag([{Calculation : "INV_Total_Power"}, {Meter : "Grid_Power_kW"}, {Calculation : "DG_Total_Power"}, {Calculation : "SL_Load_Power"}]);
                setLabel(["INV Power kW", "Grid Power kW", "DG Power kW", "Load Power kW"]);
                handlePowerTableReset();
                break;
            case 1:
                setSearchTag([{DG : "B1_DG_DG_1_Energy_Daily_kwh"}, {Calculation : "PV_Daily_Energy_Today"}, {Meter : "Grid_Daily_Energy_Export_kWh"}, {Meter : "Grid_Daily_Energy_Import_kWh"}]);
                setLabel(["DG Energy kWh", "PV Energy kWh", "Grid Consumption Daily kWh", "Grid Feed Daily kwh"]);
                handlePowerTableReset();
            break;
            case 2:
                setSearchTag([{Inverter : "B1_Inverter_Inverter_0_AC_Active_Power_Watt"}, {DG : "B1_DG_DG_0_AC_Active_Power_Watt"}]);
                setLabel(["INV_0 kW", "DG_0 kW"]);
                handlePowerTableReset();
            break;
            case 3:
                setSearchTag([{Calculation : "Daily_Runtime_Sum"}, {Calculation : "Daily_Saving_Sum"}]);
                setLabel(["Runtime (min)", "Saving (INR)"]);
            break;
            default:
                setSearchTag([{Calculation : "INV_Total_Power"}, {Meter : "Grid_Power_kW"}, {Calculation : "DG_Total_Power"}, {Calculation : "SL_Load_Power"}]);
                setLabel(["INV Power kW", "Grid Power kW", "DG Power kW", "Load Power kW"]);
            break;
        }
    };

    const downloadCSV = ()  => {
        var csvData = []
        if(currentReportData) {
            csvData.push(currentReportData[0].column);
            csvData.push(...currentReportData[0].dataFromAPI);

            var csv = Papa.unparse(csvData);
            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

            // Trigger the download
            var downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download',  `${currentReport}_${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}.csv`);
            downloadLink.click();        
        }
    }
    return (
        <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
            <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={12}>
                    <BreadcrumbLink>Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={12}>
                        Tools
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12} as={Link} to="/report">
                        Report
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <ReportSelector
                handleOptionChange={handleOptionChange}
            />
                <Box mt={5} bg={"white"}>
                    <Flex justifyContent={"space-between"} align={"center"} mb={0} bg={"white"}>
                        <Box>
                            <Image src={Cogneta} width={60} height={40} />
                        </Box>
                        <Box>
                            <Text fontFamily={"inter"} fontSize={15} fontWeight={300} letterSpacing={1} textDecoration={"underline"}>
                                {(currentCustomerName?.label)?.split(".")[1]}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontFamily={"inter"} fontSize={25} fontWeight={600} letterSpacing={1}>{currentReport}</Text>
                        </Box>
                    </Flex>
                    <Box mt={-55}>
                        <PlantViewTableLayout
                            title=""
                            width={["full", "100%"]}
                            height="600px"
                            timeWindow={true}
                            onTimeWindowChange={handleTimeWindowPowerTableChange}
                            onReset={handlePowerTableReset}
                        >
                            <Box mt={5}>
                                <PlantTable
                                    paginationLimitProps={10}
                                    width="full"
                                    search={false}
                                    sort={false}
                                    column={currentReportData? currentReportData[0].column.map((elem, i) => {
                                        if(i==0) {
                                            return ({
                                                name : "Date & Time",
                                                width : 60,
                                                formatter : (cell : any) => html(`<b style=" padding-left: 35%;">${cell}</b>`)
                                            })
                                        } else {
                                            return elem;
                                        }
                                    }) : []}
                                    apiData={currentReportData || [{ column: [], dataFromAPI: [] }]}
                                />
                            </Box>
                            <Flex justify={"center"} mt={5}>
                                <Button
                                    fontFamily={"Open Sans, sans-serif"}
                                    fontSize={"16px"}
                                    letterSpacing={2}
                                    color={"#000"}
                                    cursor={"pointer"}
                                    border={"2px solid"}
                                    p={["0.25em 0.75em", "0.25em 0.5em"]}
                                    boxShadow={"1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px"}
                                    userSelect={"none"}
                                    position={"relative"}
                                    _active={{
                                        boxShadow : "0px 0px 0px 0px",
                                        top : "5px",
                                        left : "5px"
                                    }}
                                    onClick={downloadCSV}
                                    >Download CSV</Button>
                            </Flex>
                        </PlantViewTableLayout>
                    </Box>
            </Box>
        </Box>
    );
};

export default ReportDashboard;